const messages = {
  fr: {
    parameters: {
      yes: 'ET',
    },
    com: {
      phase: {
        label: 'Marquage',
      },
      product: {
        ean: {
          label: 'EAN',
          helperText: '',
          helperTextPreview: '',
        },
        sku: {
          label: 'Code produit',
          helperText: '',
          helperTextPreview: '',
        },
        manufacturer: {
          label: 'Fabricant',
          helperText: '',
          helperTextPreview: '',
        },
        pn: {
          label: 'Numéro de travail',
          helperText: '',
          helperTextPreview: '',
        },
        warranty: {
          label: 'Garantie',
          months: 'mois',
        },
      },
      totalAmount: {
        label: 'Total',
        helperText: '',
      },
    },
    imageGallery: {
      thumbnailsMore: '+ {number} plus',
    },
    orders: {
      actions: {
        all: 'Tous les ordres',
      },
      myOrdersScoringBlog: {
        title: 'Mes commandes',
      },
      ordersList: 'Ordres',
      phase: {
        1: 'Reçue',
        '01-new': 'Reçue',
        10: 'Ouverte',
        20: 'Impayée',
        30: 'Payée',
        98: 'Terminée',
        99: 'Annulé',
      },
      listPage: {
        table: {
          columns: {
            packageId: 'Numéro de colis',
            phase: 'Statut',
            paid: 'Payé',
            total: 'Hors taxes',
            totalWithTax: 'Total TTC',
          },
        },
      },
    },
    baskets: {
      cartSinglePage: {
        title: 'Panier',
        buyButton: 'Commander',
        emptyBasket: 'Panier est vide',
        buyThere: 'Déjà client?',
        login: 'Je souhaiterais récupérer les clés de ma chambre',
        notFill: 'Coordonnées de livraison non renseignées',
        fill: 'Remplissez les informations',
        change: 'Modifier les données',
        popupButton: 'Ajouter sur la carte',
        payment: {
          title: 'MOYEN DE PAIEMENT',
          free: 'Gratuit',
          changePaymentBtn: 'MODIFIER LE MODE DE PAIEMENT',
        },
        shipping: {
          title: 'MODE DE LIVRAISON',
          free: 'Gratuit',
          changeShippingBtn: 'CHOISIR MA LIVRAISON',
          popup: {
            title: 'Informations',
            text: 'Livraison à votre adresse par coursier {service}',
          },
        },
      },
      actions: {
        all: 'Tous les paniers',
      },
      myBasketsScoringBlog: {
        title: 'Paniers enregistrés',
      },
      cartSummaryParameters: {
        caption: 'Code produit',
      },
    },
    productDetailSpagettyNavigation: {
      description: 'Description',
      parameters: 'Paramètres',
      package: 'Contenu du colis',
      fotoAndVideo: 'Image et vidéo',
      alternatives: 'Découvrez alternatives',
      alternativesText: "Pas d'alternatives pour le produit sélectionné.",
      questions: 'Questions',
      productDescriptionNotAvailable: 'Description indisponible',
    },
    newsletter: {
      phone: '+386 1 82 82 053',
      subscribe: {
        title: 'Ne manquez pas les offres spéciales',
        inputText: 'E-mail',
        button: "S'abonner",
      },
    },
    globalNavigation: {
      home: 'Accueil',
      shopping: "A l'achat",
      orders: 'Ordres',
      shoppingLists: 'Listes de courses',
      articles: 'Nouveauté',
    },
    homePageWhy: {
      0: {
        title: 'Paiment sécurisé',
        content:
          '3D Secure système de paiement par authentification permettant garantit une sécurité optimale lors de vos achats en ligne.',
      },
      1: {
        title: 'Satisfait ou remboursé',
        content:
          "Vous avez le droit de changer d'avis. Profitéz de notre service de retour et remboursement rapid dans un délai de 14 jours.",
      },
      2: {
        title: 'Stock en temps réel',
        content:
          'Marchandises dans son propre entrepôt. Nous avons 98% de toute la gamme en stock pour une expédition immédiate.',
      },
      3: {
        title: 'Livraison gratuite',
        content:
          "À l'achat d'appareils photo numériques, objectifs et autres produits sélectionnés  vous bénéficiez de la livraison GRATUITE.",
      },
      title: 'POURQUOI NOUS?',
    },
    startPage: {
      title: "Page d'accueil",
      gotoShopping: 'Aller à faire des achats',
    },
    shoppingCart: {
      actions: {
        buy: 'AJOUTER AU PANIER',
      },
      buyBtn: 'AJOUTER AU PANIER',
      alternatives: 'ALTERNATIVES',
      shipping: {
        changePoint: 'Changer',
      },
      agreements: {
        termsRequired: 'Veuillez accepter les conditions commerciales.',
      },
      validation: {
        someProductNotAvailable: 'Certains produits ne sont pas en stock en quantités suffisantes.',
      },
    },
    appCartSummaryProductParameters: {
      title: 'Code produit',
    },
    productListPage: {
      title: 'Catalogue',
      orders: {
        recommended: 'Pertinence',
        name: 'A - Z',
        priceAsc: 'Prix croissant',
        priceDesc: 'Prix décroissant',
      },
    },
    orderStatuses: {
      storno: 'Annulation',
      synchronized: 'Expédié',
      waiting: 'En attente de synchronisation',
    },
    orderPreview: {
      items: 'Produits',
    },
    cartRegisterForm: {
      title: "Form d'inscription",
    },
    cartPayment: {
      title: 'MODE DE PAIEMENT',
    },
    cartPreSummaryPage: {
      title: 'Récapitulatif du panier',
      actions: {
        nextStep: "J'accepte et continue",
      },
    },
    cartCompanyForm: {
      description: 'Remplir si vous achetez des biens pour une entreprise',
    },
    cartDeliveryForm: {
      description: "Remplir si l'adresse de livraison est différente de l'adresse de facturation",
    },
    productAvailabilityFieldPreviewWithPopup: {
      button: 'Voir les options de livraison',
    },
    productDetailStockInfo: {
      available: 'Le produit disponible immédiatement dans notre entrepôt de distribution.',
      external: 'Le produit est en route vers notre entrepôt',
      notAvailable: 'Les marchandises sont temporairement indisponibles',
      labels: {
        free: 'LIVRAISON GRATUITE',
        showroom: 'EXPOSÉ DANS LE SHOWROOM',
      },
    },
    productGifts: {
      btnName: 'CADEAU',
      giftValue: 'En valeur de',
    },
    homepage: {
      productOfMonth: 'Vous allez les aimer',
      catalogFavorite: 'Le meilleur de nos catégories',
      hotTips: 'À ne pas manquer !',
      recommended: 'Notre sélection',
    },
    productQuestion: {
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      email: 'E-mail',
      subject: ' ',
      question: 'Votre question',
      gdpr: "J'accepte le traitement des données personnelles afin d'obtenir une réponse à la question.",
      gdprLink: 'Lien RGPD',
      button: 'Envoyer',
      subjectContent: 'Question sur le produit : {product}, code : {sku}',
      title: 'Vous devez accepter le traitement des données personnelles.',
      success: 'Le formulaire a été soumis',
    },
    lastVisitedProducts: 'Produits récemment consultés',
    productAlternativeProducts: 'Alternatives',
    productAccessoryProducts: 'Accessoires',
    productReplacementProducts: 'Ajouter',
    productAccessories: 'Ajouter',
    productReplacements: 'Remplacement',
    appProductDetailTabs: {
      description: 'Description',
      parameters: 'Paramètres',
      package: 'Contenu du colis',
      query: 'Une question',
      media: 'Image et vidéo',
      alternatives: 'Alternative',
      accessories: 'Accessoires',
      replacements: 'Accessoires',
      form: {
        enum: '!!!! VALEUR ENUM',
      },
    },
    cartSummaryPage: {
      title: 'Récapitulatif avant de commander',
      actions: {
        continueShopping: 'Continuer vos achats',
        saveOrder: 'Créer une commande',
      },
      expectedDeliveryDate: {
        label: 'Date de livraison requise',
        helperText: '',
        helperTextPreview: '',
      },
      noteForSeller: {
        label: 'Avis au livreur',
        helperText: '',
        helperTextPreview: '',
      },
      paymentGateway: {
        label: 'Passerelle de paiement',
        helperText: '',
        helperTextPreview: '',
      },
    },
    userProfile: {
      title: 'Mon compte',
      content: 'Contenu',
      contactInfo: {
        newsletter: {
          title: 'Bulletin',
          content: 'Je souhaite recevoir les actualités de Digiexpert.fr',
        },
        title: 'détails du contact',
      },
      orders: {
        title: 'Historique des commandes',
      },
      invoices: {
        title: 'Mes factures',
      },
      myOrders: 'Mes commandes',
      myInvoices: 'Mes factures',
      logout: 'Se déconnecter',
      login: 'Se connecter',
      favorites: {
        title: 'Ma liste d’envies',
      },
      changePassword: {
        title: 'Changement de mot de passe',
        fields: {
          login: 'Identifiant',
          originalPassword: "Mot de passe d'origine",
          newPassword: 'Nouveau mot de passe',
          confirmNewPassword: 'Confirmez le mot de passe',
        },
        button: 'Sauvegarder les modifications',
      },
    },
    orderSavedThankPage: {
      title: 'Merci pour votre commande {orderNumber}',
      thankMessage: {
        email: 'info@digiexpert.fr',
        phoneNumber: '',
        template:
          "Veuillez vérifier votre email pour confirmer votre commande. Enregistrez l'e-mail pour votre propre usage. Vous trouverez toutes les informations concernant votre commande dans la section ci-dessous.",
      },
      order: 'Numéro de commande',
      payment: 'Paiement du jour ',
      cancelled: 'Annulé',
      accepted: 'Reçu',
      pending: 'En attendant',
      paid: 'Payé',
      actions: {
        back: "Retour à la page d'accueil",
        detail: 'Récapitulatif de la commande',
        continue: 'Continuer vos achats',
      },
      decline: 'Rejeté',
    },
    shoppingLists: {
      title: 'Listes de courses',
      actions: {
        saveAsShoppingList: 'Enregistrer comme liste de courses',
        importShoppingListToCart: 'Importer',
      },
    },
    searchResultPage: {
      search: 'Recherche ',
      results: 'Résultats de recherche',
    },
    homePageArticles: {
      title: 'Nouveautés',
      button: 'Derniers articles',
    },
    loginPage: {
      title: 'Connexion',
      forgotPassword: 'Réinitialisation du mot de passe',
      email: 'E-mail',
      sendEmail: 'Envoyer un e-mail',
      info: 'Vous recevrez un e-mail avec un lien pour changer votre mot de passe',
      password: 'Mot de passe',
      notSame: 'Le mot de passe ne correspond pas',
      newPassword: 'Nouveau mot de passe',
      confirmPassword: 'Confirmez le mot de passe',
      changePassword: 'Changement de mot de passe',
    },
    productPrice: {
      withoutTax: 'prix HT',
    },
    productDetailPage: {
      availability: {
        dateRange: 'Commandez {today} et nous vous livrerons dans les {minDays}-{maxDays} jours.',
        today: "Commandez avant {time} et nous l'expédierons aujourd'hui.",
        todayWord: "aujourd'hui",
        tomorrow: "Commandez {today} et nous l'expédierons demain.",
        nextDateTime: '13,30',
        dayOfWeek: {
          message: "Commandez {today} et nous l'expédions {jour}.",
          1: 'Lundi',
          2: 'Mardi',
          3: 'Mercredi',
          4: 'Jeudi',
          5: 'Vendredi',
          6: 'Samedi',
          0: 'Dimanche',
        },
      },
      allDescription: 'DESCRIPTIF DÉTAILLÉ',
      demoProduct: {
        text: 'ACHETER MOINS CHER pour :',
      },
      productNote: {
        title: 'ACHETER MOINS CHER pour : 2 150,00 €',
        note: "Remarque 2 - Description supplémentaire de l'état ou des informations similaires sur le produit pourquoi il bénéficie d'une remise et ce qu'il y a sur les marchandises...",
      },
      adornments: {
        notForSale: 'Pas à vendre en particulier',
        usedProduct: 'Seconde vie',
        freeGift: 'Un cadeau gratuit',
        freeShipping: 'Livraison gratuite',
        eol: 'Vente fermée',
        demo: 'Produit de démonstration',
        sale: 'Soldes',
      },
      disponibility: {
        primaryLessThan: 'Moins de {count} pièces EN STOCK',
        primaryMoreThan: 'EN STOCK plus que {count, plural, zero {une pièce} other {{count} pièces}}',
        secondaryLessThan: 'EN STOCK en entrepôt de préparation moins de {count} unités',
        secondaryMoreThan:
          "EN STOCK dans l'entrepôt de préparation plus que {count, plural, zero {une pièce} other {{count} pièces}}",
        external: 'Le produit est en route vers notre entrepôt',
        unavailable: "Le produit n'est pas disponible",
        showroom: "(présenté dans la salle d'exposition)",
        soldout: "Le produit n'est pas disponible",
        'on-way': {
          d: '',
          m: 'En route pour {from, plural, one {minute} two {minute} other {minute}}',
          M: 'En route pour {from, plural, one {mois} two {mois} few {mois} other {mois}}',
          dRange: 'En route pour {to, plural, few {{from}-{to} days} other {{from}-{to} days}}',
          mRange: 'Il y a {from}-{to, plural, one {than} a few {who} other {who}}',
          MRange: 'Sur le chemin {from}-{to, plural, one {mois} two {mois} few {mois} other {mois}}',
          date: '{datum}',
        },
        time: 'En route plus que {count plural zero {une pièce} other {{count} pièces}}',
      },
    },
    comparePage: {
      title: 'Comparer',
      removeButton: 'Supprimer le produit',
      nothing: 'Pas de produits à comparer',
    },
    filter: {
      reset: 'Réinitialiser le filtre',
      search: 'Chercher',
    },
    orderTermsAndConditions: {
      select: {
        label: 'Conditions de commande',
        helperText: '',
        helperTextPreview: '',
      },
    },
    orderDetailPage: {
      actions: {
        import: 'Ajouter au panier',
      },
    },
    invoiceBlogPreview: {
      title: 'Mes comptes',
      button: 'Tous les comptes',
    },
    userProfilePage: {
      logout: 'Déconnexion',
      setting: 'Réglages',
      passwordChange: 'Changement de mot de passe',
    },
    appBarActionRegion: {
      changeRegion: 'Changer la langue',
      label: 'Valider',
    },
    subCategories: {
      title: 'Le meilleur de nos catégories',
    },
    aboutShoppingPage: {
      title: "TOUT SUR L'ACHAT",
      contact: 'CONTACTEZ',
      electronicClosureOfRevenue: 'ENREGISTREMENT ÉLECTRONIQUE DES REVENUS',
      informationDuty: "OBLIGATIONS D'INFORMATION",
      processingOfPersonalData: 'TRAITEMENT DES DONNÉES PERSONNELLES',
      cashback: 'REMISE EN ARGENT',
      delivery: 'LIVRAISON',
      availability: 'DISPONIBILITÉ',
      methodOfPayment: 'MOYEN DE PAIEMENT',
      termsAndConditions: 'TERMES ET CONDITIONS',
      termsOfAppeal: "CONDITIONS D'APPEL",
      contractwWithdrawal: 'RETRAIT DU CONTRAT',
    },
    customer: {
      viesValidation: {
        viesVerified: 'Votre numéro fiscal a été vérifié les prix sont facturés sans TVA.',
        viesNotVerified: "Votre numéro fiscal n'a pas été vérifié ou vous n'êtes pas éligible pour un achat sans TVA.",
      },
    },
    cartCustomerForm: {
      dataForDelivery: 'INFORMATIONS CLIENT',
      pleaseComplete: 'VEUILLEZ COMPLÉTER',
      closeButton: 'Valider',
    },
    cartPromoCodes: {
      title: 'APPLIQUER LE CODE PROMO',
      textFieldPlaceholder: 'CODE PROMO',
      textFieldHelperText: 'Un seul code promo peut être appliqué par commande',
      applyPromoCodeButton: 'APPLIQUER',
    },
    cookiesBar: {
      agreeBtn: 'Accord',
      title: 'AVIS CONCERNANT LES COOKIES SUR NOTRE SITE WEB',
      text: 'Notre société utilise ses propres cookies (appelés cookies essentiels) et des cookies tiers sur ce site Web. Sur la base de ces fichiers et intérêts nous adaptons les sites Internet pour répondre aux attentes des clients optimiser nos offres et prévenir les risques de fraude. Sans certains cookies nécessaires le site Web ne peut pas fonctionner correctement. En cliquant sur le bouton "Accepter tout"',
    },
    cartFormLogin: {
      btnSend: 'Envoyer',
      button: 'Se connecter',
      title: 'Connexion / Inscription',
      forgottenPasswordBtn: 'Mot de passe oublié?',
      text1: "Nous vous enverrons un mot de passe à usage unique à l'adresse e-mail indiquée.",
      text2:
        "Nous vous conseillons d'utiliser un mot de passe propre à Digiexpert.fr et de ne pas l'enregistrer dans votre navigateur.",
      error: 'Identifiant ou mot de passe incorrect.',
    },
    cartSummartSection: {
      title: 'RÉCAPITULATIF DE LA COMMANDE',
    },
    appCartSummarySubjectsList: {
      title: 'ARTICLE',
      text: 'DÉSIGNATION',
    },
    newsletterText: {
      text1: "Besoin d'un conseil?",
      text2: 'Contactez-nous les jours ouvrables de 8h00 à 16h00.',
    },
    cartSummarySection: {
      labelWithoutVAT: '(hors taxes)',
      priceLabel: 'PRIX U. TTC',
      amount: 'QUANTITÉ',
      totalPriceLabel: 'TOTAL TTC',
      totalPrice: 'Total',
      submitButton: 'COMMANDER',
    },
    cartSummarySubjectsListItemDirectPreview: {
      amountTitle: '',
    },
    cartSummaryListItem: {
      freeTitle: '- Gratuit',
      amountTitle: 'units',
    },
    sxCartNotes: {
      note: 'Il ne sert pas à indiquer la date de livraison',
      addNote: 'Laisser un message',
    },
    productDetail: {
      promoCode: {
        title: 'avec le code promo:',
        validTo: 'valable jusqu’à:',
      },
      productSendLink: {
        copyLinkBtn: 'Copiez le lien',
        sendLinkByEmail: 'Envoyer le lien par email',
      },
      productDetailBarInformation: {
        addToFavourites: "Liste d'envies",
        compareProduct: 'Comparez',
        sendLink: 'Partager',
        serviceList: 'A vos côtés',
        questions: 'Questions',
      },
    },
    cartFormShipping: {
      checkbox: {
        invoiceToCompany: "FACTURE D'ENTREPRISE",
        otherDeliveryInfo: 'AUTRE ADRESSE DE LIVRAISON',
        otherDeliveryInfoAlert: "L'assujetti ne peut pas utiliser une autre adresse pour la livraison.",
      },
      companyNameLabel: 'Raison sociale',
      firstNameLabel: 'Prénom',
      lastNameLabel: 'Nom de famille',
      streetLabel: 'Rue',
      cityLabel: 'Ville',
      zipLabel: 'Code postal',
      companyIDLabel: "Numéro d'identification fiscale",
      countryLabel: 'Pays',
    },
    cartFormPersonal: {
      label: 'Adresses de livraison',
      firstNameLabel: 'Prénom',
      lastNameLabel: 'Nom de famille',
      streetLabel: 'Numéro de rue / maison',
      pscLabel: 'Code postal',
      cityLabel: 'Ville',
      countryLabel: 'Pays',
      phoneLabel: 'Numéro de téléphone',
      emailLabel: 'Adresse e-mail',
      passwordLabel: 'Mot de passe',
      controlPasswordLabel: 'Mot de passe de contrôle',
    },
    cartFormInvoice: {
      labelDesktop: 'INFORMATIONS CLIENT',
      labelMobile: 'détails du contact',
      companyLabel: 'Raison sociale',
      password: 'Mot de passe',
      confirmPassword: 'Vérification du mot de passe',
      email: 'Adresse e-mail',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      countryLabel: 'Pays',
      phone: 'Numéro de téléphone',
      phoneError: "Le numéro de téléphone n'est pas correct",
      streetLabel: 'Rue',
      streetNumberLabel: 'Numéro de maison',
      zipLabel: 'code postal',
      icoLabel: 'Non',
      dicLabel: "numéro d'identification fiscale",
      country: 'Pays',
      cityLabel: 'Ville',
      registration: 'Créer un compte client',
      registrationNote: 'Nouveau client ?',
      button: 'Sauvegarder les modifications',
    },
    page404: {
      title: "Malheureusement la page n'existe pas...",
      subTitle: "Retour à la page d'accueil.",
      button: "Retour à la page d'accueil.",
    },
    productListOrderSelectorButton: {
      selectLabel: 'Trier par',
      byName: 'Titre A-Z',
      byPrice: 'Prix',
      onlyStock: 'Uniquement en stock',
      resetFilter: 'SUPPRIMER',
      displayCountOfProducts: 'Par page',
      search: 'Chercher',
      clear: 'Effacer',
      ok: 'APPLIQUER',
    },
    priceRangeSliderField: {
      title: 'Échelle des prix',
    },
    productListMultipleFilterMobileDialog: {
      headerTitle: 'Filtrer par',
    },
    productListMultipleFilterMobile: {
      title: 'FILTRE',
    },
    cartCompany: {
      companyName: 'Raison sociale',
      regNo: "Numéro fiscal de l'entreprise",
      taxNo: "Numéro d'identification TVA",
      taxNoPlaceholder: 'FR00000000000',
      warningMessage: {
        warning: 'Important.',
        message:
          "En cas de livraison à une adresse autre que celle de l'entreprise la commande sera facturée avec la TVA.",
      },
    },
    searchResultsPage: {
      match: 'Nous avons trouvé {resultCountFormatted} résultats pour {searchText}.',
      nomatch: "Aucun résultat n'a été trouvé pour {searchText}.",
      matchingCategories: 'Catégories trouvées',
      button: "Retour à la page d'accueil.",
    },
    selectMultipleFilter: {
      totalChipsLabel: 'Produits {total} sélectionnés',
    },
    cartDelivery: {
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      companyName: 'Raison sociale',
      streetLabel: 'Rue',
      streetNumberLabel: 'Numéro de maison',
      zipLabel: 'code postal',
      country: 'Pays',
      cityLabel: 'Ville',
    },
    cartNotes: {
      shippingLabel: 'Ajouter des instructions pour la livraison',
      shippingNote: 'Note au transporteur',
      sellerLabel: 'Ajouter une note au vendeur',
      sellerNote: 'Note au vendeur',
      loginNote:
        "Si vous avez déjà un compte chez nous le formulaire sera rempli automatiquement lors de l'inscription.",
    },
    cartCountryShipping: {
      title: 'CHOISIR MA LIVRAISON',
      label: 'Le choix du pays affecte le mode de paiement et de transport ainsi que la date de livraison',
    },
    cartAddress: {
      bllingAddress: 'Adresse de livraison de la facture',
      deliveryAddress: 'Adresse de livraison',
      pickUpPointAddress: 'Adresse du point de retrait',
    },
    cartAgreements: {
      customerVerified:
        "Je n'accepte pas de recevoir une enquête de satisfaction client pour aider à améliorer nos services.",
    },
    loginDialog: {
      username: 'Identifiant  ',
      password: 'Mot de passe',
    },
    sprinxFormsValidation: {
      confirmPassword: 'Les mots de passe saisis ne sont pas les mêmes',
      invalidPostalCode: "Le code postal n'est pas dans un format valide",
      usernameIsNotFree: 'Un utilisateur portant ce nom est déjà enregistré.',
      emailEmpty: 'E-mail correct !',
    },
    invoices: {
      actions: {
        addNewInvoice: 'Ajouter un nouveau compte',
        all: 'Tous les comptes',
      },
      listPage: {
        table: {
          columns: {
            downloadLink: 'PDF',
            number: 'Numéro',
            invoiceDate: 'Date de facturation',
            dueDate: 'A la date de',
            total: 'Hors taxes',
            totalWithTax: 'Total TTC',
            billingAddress: 'Adresse de livraison de la facture',
            orderNumber: 'Numéro de commande',
            unpaidAmount: 'Montant impayé',
          },
          filter: {
            number: 'Numéro',
          },
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Mon compte',
          columns: {
            priceUnit: "Prix à l'unité",
          },
        },
      },
    },
    cartSummaryTotal: {
      totalPrice: '',
      totalPriceWithTax: 'Total de votre panier',
      tax: 'T.V.A.',
      withoutTax: 'Prix total hors TVA',
    },
    compare: {
      addToCompare: 'Le produit a été ajouté au comparateur',
      removeToCompare: 'Le produit a été retiré du comparateur',
      price: 'Prix',
      ean: 'EAN',
    },
    favorite: {
      addToFavorite: "Le produit a été ajouté à la liste d'envies",
      removeFromFavorite: "Le produit a été supprimé de la liste d'envies",
    },
    registration: {
      important: 'IMPORTANT!',
      note: 'Nous vous enverrons un mot de passe à usage unique par e-mail. Une fois connecté entrez un nouveau mot de passe.',
      gdprAgreement: 'Veuillez accepter le traitement des données personnelles',
      submitButton: 'Créer mon compte',
      fail: 'Une erreur est survenue, merci de réessayer.',
      success: 'La connexion a réussi',
      title: 'Inscription',
      dialog: "Déjà client? Si vous n'avez pas encore de compte vous pouvez vous inscrire",
      here: 'ici',
    },
    appBarActionGlobalSearchComposed: {
      label: 'Rechercher des produits',
    },
    appBarActions: {
      about: "Tout sur l'achat",
      aboutHref: '/tout-sur-l-achat',
      compare: 'Comparer',
    },
    appBarActionUser: {
      title: 'Identifiez-vous',
    },
    footer: {
      copyright: 'COPYRIGHT © ČESKÝ VELKOOBCHOD S.R.O. tous les droits sont réservés',
    },
    appArticlesPage: {
      title: 'Articles',
    },
    errorPage: {
      title: 'Quelque chose ne va pas.',
      button: "Page d'accueil",
    },
    cartSummary: {
      withoutTax: 'hors taxes',
    },
    renewPasswordForm: {
      success: 'Un nouveau mot de passe a été envoyé à votre adresse e-mail.',
      emailNotFound: 'Email non trouvé !',
      error: 'Quelque chose ne va pas',
    },
    cartSubjectListItemQuantityWarning: 'La quantité maximale de commande est de {count} produit(s)',
  },
};
export default messages;
