const messages = {
  fr: {
    com: {
      components: {
        headerCartButton: {
          cartButton: 'Mon panier',
        },
      },
      productDetail: {
        parameters: {
          title: 'Paramètres',
        },
      },
      productAvailability: {
        format: {
          available: 'En stock',
          moreThanX: 'Plus que {min} articles en stock',
          exactCount: '{value} articles en stock',
          notAvailable: 'Indisponible',
        },
      },
      imageGallery: {
        thumbnailsMore: '+ {number} plus',
      },
      relatedProducts: {
        title: 'Les clients ont également apprécié',
      },
      productAccessories: {
        title: 'Accessoires',
      },
      productDetailImageGallery: {
        noImage: 'Pas de photo',
      },
      productAvailabilityFieldPreviewWithPopup: {
        button: 'Voir les options de livraison',
      },
    },
  },
};
export default messages;
