const messages = {
  fr: {
    reactMuiTables: {
      selected: 'Sélectionné',
      filterhide: 'Masquer le filtre',
      filtershow: 'Afficher le filtre',
      refresh: 'Réinitialiser',
      filter: {
        actions: {
          search: 'Chercher',
          resetFilter: 'Réinitialiser le filtre',
          addCondition: 'Ajouter une condition',
        },
      },
      standardOperators: {
        equals: 'équivaut à',
        contains: 'contient',
        between: {
          label: 'entre',
          from: 'de',
          to: 'à',
        },
        oneOf: 'un des',
      },
      labelRowsPerPage: 'Lignes par page:',
    },
  },
};
export default messages;
