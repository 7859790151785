const messages = {
  fr: {
    shoppingCart: {
      actions: {
        addToCart: 'Ajouter au panier',
        buy: 'Commander',
        order: 'Commande',
        remove: 'Supprimer',
      },
      cart: {
        total: {
          label: 'Total',
          helperText: '',
          helperTextPreview: '',
        },
        totalItems: {
          label: 'Total par lignes',
          helperText: '',
          helperTextPreview: '',
        },
        totalShipping: {
          label: 'Livraison',
          helperText: '',
          helperTextPreview: '',
        },
        totalPayment: {
          label: 'Total à payer',
          helperText: '',
          helperTextPreview: '',
        },
        totalTaxes: {
          label: 'Taxe total',
          helperText: '',
          helperTextPreview: '',
        },
        resetCart: 'Vidier le panier',
      },
      messages: {
        confirmResetCart: 'Êtes-vous sûr de vidier le panier ?',
        giftClaim: 'Vous pouvez obtenir {quantity} pièces de {productName} comme un cadeau.',
        cartManipulations: {
          allRemoved: 'Tous les articles ont été supprimés du panier.',
          itemAdded: '{number, plural, one {Article ajouté au panier} other {Articles ajoutés au panier}}.',
          itemRemoved: '{number, plural, one {Article supprimé du panier} other {Articles retirés du panier}}.',
          quantityChaged: 'La quantité a changé.',
        },
      },
      components: {
        appBartActionCart: 'Panier',
        quantityField: {
          states: {
            quantityRounded: "La quantité a été arrondie à la taille d'emballage la plus proche.",
          },
        },
        cartAsidePreview: {
          cartEmpty: 'Le panier est vide',
        },
        cartAfterAddModal: {
          continueShopping: 'Continuer mes achats',
          goToCart: 'Voir le panier',
          title: 'Ajouté au panier',
          pcs: 'units',
        },
      },
    },
  },
};
export default messages;
