export interface ApplicationSettings2 {
  currency: string;
  currencyTypes: string[];
  locale: string;
  preventDecrementRemoveSubject: boolean;
  pricesWithTaxes: boolean;
  shop: {
    billing: {
      includeCountries: [string, string][];
    };
    contacts: {
      phoneNumbers: {
        includeCountries: [string, number][];
      };
    };
    country: string;
    shipping: {
      includeCountries: [string, string][];
    };
    viesInvoiceCountry: string;
  };
}
const applicationSettings: ApplicationSettings2 = {
  currency: 'EUR-FR',
  currencyTypes: ['EUR-FR'],
  locale: 'fr',
  shop: {
    country: 'FR',
    viesInvoiceCountry: 'CZ',
    billing: {
      includeCountries: [
        ['AT', 'Austria'],
        ['BE', 'Belgium'],
        ['BG', 'Bulgaria'],
        ['HR', 'Croatia'],
        ['CY', 'Cyprus'],
        ['CZ', 'Česko'],
        ['DK', 'Denmark'],
        ['EE', 'Estonia'],
        ['FI', 'Finland'],
        ['FR', 'France'],
        ['DE', 'Germany'],
        ['GR', 'Greece'],
        ['HU', 'Hungary'],
        ['IE', 'Ireland'],
        ['IT', 'Italy'],
        ['LV', 'Latvia'],
        ['LT', 'Lithuania'],
        ['LU', 'Luxembourg'],
        ['MT', 'Malta'],
        ['NL', 'Netherlands'],
        ['PL', 'Poland'],
        ['PT', 'Portugal'],
        ['RO', 'Romania'],
        ['SK', 'Slovakia'],
        ['SI', 'Slovenia'],
        ['ES', 'Spain'],
        ['SE', 'Sweden'],
      ],
    },
    shipping: {
      includeCountries: [
        // ['AT', 'Austria'],
        // ['BE', 'Belgium'],
        // ['BG', 'Bulgaria'],
        // ['HR', 'Croatia'],
        // ['CY', 'Cyprus'],
        // ['CZ', 'Česko'],
        // ['DK', 'Denmark'],
        // ['EE', 'Estonia'],
        // ['FI', 'Finland'],
        ['FR', 'France'],
        // ['DE', 'Germany'],
        // ['GR', 'Greece'],
        // ['HU', 'Hungary'],
        // ['IE', 'Ireland'],
        // ['IT', 'Italy'],
        // ['LV', 'Latvia'],
        // ['LT', 'Lithuania'],
        // ['LU', 'Luxembourg'],
        // ['MT', 'Malta'],
        // ['NL', 'Netherlands'],
        // ['PL', 'Poland'],
        // ['PT', 'Portugal'],
        // ['RO', 'Romania'],
        // ['SK', 'Slovakia'],
        // ['SI', 'Slovenia'],
        // ['ES', 'Spain'],
        // ['SE', 'Sweden'],
      ],
    },
    contacts: {
      phoneNumbers: {
        includeCountries: [
          ['AT', 43],
          ['BE', 32],
          ['BG', 359],
          ['HR', 385],
          ['CY', 357],
          ['CZ', 420],
          ['DK', 45],
          ['EE', 372],
          ['FI', 358],
          ['FR', 33],
          ['DE', 49],
          ['GR', 30],
          ['HU', 36],
          ['IE', 353],
          ['IT', 39],
          ['LV', 371],
          ['LT', 370],
          ['LU', 352],
          ['MT', 356],
          ['NL', 31],
          ['PL', 48],
          ['PT', 351],
          ['RO', 40],
          ['SK', 421],
          ['SI', 386],
          ['RS', 381],
          ['ES', 34],
          ['SE', 46],
        ],
      },
    },
  },
  pricesWithTaxes: true,
  preventDecrementRemoveSubject: true,
};

export default applicationSettings;
