const messages = {
  fr: {
    app: {
      about: "À propos de l'application",
      actions: {
        add: 'Ajouter',
        addNamed: 'Ajouter {name}',
        remove: 'Supprimer',
        removeNamed: 'Supprimer {name}',
        removeConfirmation: 'Etes-vous sûr de supprimer?',
        more: 'Plus',
        less: 'Moins',
        download: 'Télécharger',
        save: 'Sauvegarder',
        cancel: 'Annuler',
        close: 'Valider',
        moreFormFields: 'Plus de champs',
        export: 'Exporter',
        edit: 'Éditer',
        createdSuccessMessage: 'Créé avec succès.',
        savedSuccessMessage: 'Enregistré avec succès.',
        deletedSuccessMessage: 'Supprimé avec succès.',
        detail: 'Détail',
        next: 'Poursuivre',
        back: 'Retour',
        start: 'Début',
        whatNext: 'Que ce passe t-il après...',
        confirm: 'Confirmer',
        confirmRemove: 'Etes-vous sûr de supprimer?',
        search: 'Chercher',
        userProfile: "Profil de l'utilisateur",
        tenantProfile: 'Votre profil CRM',
        imports: 'Importer',
        fileImports: 'Importer des fichiers',
        settings: 'Réglages',
        logout: 'Déconnexion',
        login: 'Connexion',
        filterList: 'Liste de filtres ',
        excel: 'Excel',
        refresh: 'Rafraîchir',
        clear: 'Dégager',
        agree: "Je suis d'accord",
        disagree: "Je ne suis pas d'accord",
        makeCopy: 'Faire une copie',
        fullscreen: 'Afficher en plein écran',
        fullscreenClose: "Fermer l'affichage plein écran",
        copy: 'Copie',
      },
      messages: {
        confirmRemove: 'Supprimer le produit ?',
        errorOccurred: 'Une erreur est survenue',
        createdSuccess: 'La création a réussi.',
        savedSuccess: 'La sauvegarde a réussi.',
        deletedSuccess: 'La suppression a réussi.',
        uploadSuccess: 'Fichier téléchargé avec succès.',
        uploadError: 'Erreur lors du téléchargement du fichier.',
        uploadInProgress: 'Téléchargement...',
        uploadedFiles:
          '{number, plural, one {1 fichier téléchargé} few {{number} fichiers téléchargés} other {{number} fichiers ont été téléchargés}}',
      },
      error: 'Erreur!',
      validations: {
        isRequired: 'Le champ {field} obligatoire.',
        minLengthIsRequired:
          'Au moins {number, plural, one {1 article} few {{number} articles} other {{number} produits}} sont requis.',
        minValue: 'La valeur minimale autorisée est {value}.',
        maxValue: 'La valeur maximale autorisée est {value}.',
        passwordMatchError: 'Les mots de passe ne correspondent pas',
        invalidEmail: 'E-mail invalide.',
        invalidPhoneNumber:
          "Le numéro de téléphone doit être dans un format international valide ! '+ XXX XXX XXX XXX '!",
        notValidNumber: 'Numéro invalide',
        invalidUrl: 'Adresse URL invalide.',
        positive: 'Le nombre doit avoir une valeur positive.',
        negative: 'Le nombre doit avoir une valeur négative.',
      },
      login: {
        title: 'Connectez-vous à Sprinx CRM',
        username: 'Identifiant',
        password: 'Mot de passe',
        loginButton: 'Connexion',
        error: 'Erreur, identifiant ou mot de passe introuvable',
        pleaseRelogin: "Vous n'êtes pas connecté. Veuillez réessayer de vous connecter.",
      },
      days: '{count, plural, one {1 jour} few {{formattedCount} jours} other {{formattedCount} jours}}',
      overdue: 'zamuda {count, plural, one {1 jour} few {{formattedCount} jours} other {{formattedCount} jours}}',
      newItem: 'Nouveau',
      anonymizing: {
        confirm: {
          title: 'Confirmer',
          content: 'Êtes-vous sûr de vouloir anonymiser les données personnelles ?',
        },
        button: 'Anonymisation des données personnelles',
      },
      appMenu: {
        discover: 'Découvrir',
      },
      missingType: "Veuillez d'abord spécifier {typeName} dans l'administration de l'application.",
      missingTypeForEntityType:
        "Veuillez d'abord définir {typeName} pour {forEntityTypeName} dans l'administration de l'application.",
      emptyScoringBlog:
        'Les modules dont le contenu ou les paramètres sont affichés sur cette page ne sont pas activés.',
    },
    components: {
      buttons: {
        showOnMap: 'Afficher sur la carte',
      },
      filters: {
        filterButtonSubmit: 'Chercher',
        filterFieldName: '{name} ({conditionType})',
        addCondition: 'Ajouter une condition',
        resetFilter: 'Réinitialiser le filtre',
        operators: {
          contains: {
            0: 'contient',
            _: 'contient',
          },
          containsexact: {
            0: 'contient exactement',
            _: 'contient exactement',
          },
          notcontains: {
            0: 'ne contient pas',
            _: 'ne contient pas',
          },
          betweennumber: {
            0: 'de',
            1: 'à',
            _: 'entre',
          },
          betweendate: {
            0: 'de',
            1: 'à',
            _: 'entre',
          },
          equal: {
            0: 'est le même',
            _: 'est le même',
          },
        },
      },
      paginationList: {
        itemsTotal: 'Total produits : {number}',
        nextPage: '{number} produits suivantes',
        emptyMessage: 'Aucun produit trouvé.',
      },
      selects: {
        emptyOption: 'Aucun',
        emptyMessage: 'Aucun produit trouvé.',
      },
      tables: {
        labelRowsPerPage: 'Une ligne sur la page',
        sortBy: 'Trier par {field}',
        recordsChanged: 'Certains enregistrements ont ajoutés ou modifiés.',
      },
      textField: {
        errorMsgWrapper: '',
      },
      dateField: {
        invalidDateFormat: 'Format de date irrégulier.',
        invalidDate: 'Inconnue',
        maxDateMessage: 'La date ne peut pas être supérieure à la date maximale',
        minDateMessage: 'La date ne doit pas être inférieure à la date minimale',
        today: "Aujourd'hui",
      },
      detailNavigation: {
        scoringblog: 'Blogue de notation',
        card: 'Carte',
        relations: 'Être',
      },
      manipulationInfo: {
        created: 'Établi',
        updated: 'Modifié',
        removed: 'Supprimé',
      },
    },
  },
};
export default messages;
