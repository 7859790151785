const messages = {
  fr: {
    orders: {
      actions: {
        addNewOrder: 'Nouvelle commande',
        all: 'Tous les commandes',
      },
      listPage: {
        title: 'Commandes',
        table: {
          filter: {
            number: 'Numéro',
          },
          columns: {
            contact: 'Contact',
            dateOfOrder: 'Date de commande',
            number: 'Numéro',
            offer: 'Offre',
            opportunity: 'Occasion',
            owner: 'Le propriétaire',
            state: 'Statut',
            total: 'Total',
            totalUserCurrency: 'Total {currency}',
          },
        },
      },
      order: {
        newEntity: 'Nouvelle commande',
        contact: {
          label: 'Contact',
          helperText: '',
          helperTextPreview: '',
        },
        commissionTotal: {
          label: 'Commission totale',
          helperText: '',
          helperTextPreview: '',
        },
        currency: {
          label: 'Devise',
          helperText: '',
          helperTextPreview: '',
        },
        dateOfOrder: {
          label: 'Date de commande',
          helperText: '',
          helperTextPreview: '',
        },
        number: {
          label: 'Numéro',
          helperText: 'Laissez vide pour la complétion automatique.',
          helperTextPreview: '',
        },
        offer: {
          label: 'Offrir',
          helperText: '',
          helperTextPreview: '',
        },
        opportunity: {
          label: 'Occasion',
          helperText: '',
          helperTextPreview: '',
        },
        owner: {
          label: 'Le propriétaire',
          helperText: '',
          helperTextPreview: '',
        },
        salesMargin: {
          label: 'Marge commerciale',
          helperText: '',
          helperTextPreview: '',
        },
        subjects: {
          title: 'Subject',
          discount: {
            label: 'Remise',
            helperText: '',
            helperTextPreview: '',
          },
          commission: {
            label: 'Commission',
            helperText: '',
            helperTextPreview: '',
          },
          list: {
            package: 'Taille du paquet',
          },
          listPrice: {
            label: 'Prix catalogue',
            helperText: '',
            helperTextPreview: '',
          },
          price: {
            label: 'Prix ',
            helperText: '',
            helperTextPreview: '',
          },
          product: {
            label: 'Produit',
            helperText: '',
            helperTextPreview: '',
          },
          quantity: {
            label: 'Quantité',
            helperText: '',
            helperTextPreview: '',
          },
          salesMargin: {
            label: 'Marge commerciale',
            helperText: '',
            helperTextPreview: '',
          },
          subjectTotal: {
            label: 'Total',
            helperText: '',
            helperTextPreview: '',
          },
          unit: {
            label: 'Unité',
            helperText: '',
            helperTextPreview: '',
          },
        },
        total: {
          label: 'Total',
          helperText: '',
          helperTextPreview: '',
        },
        numberOfSubjects:
          '{number, plural, =0 {aucun produit} one {1 produit} few {{number} produits} other {{number} produits}}',
      },
      myOrdersScoringBlog: {
        title: 'Mes commandes',
      },
      ordersList: 'Commandes',
    },
  },
};
export default messages;
