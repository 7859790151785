const messages = {
  fr: {
    sprinxFormsValidation: {
      required: 'Champ obligatoire!',
      string: 'Doit être une chaîne de texte !',
      stringEmpty: 'Ne doit pas être vide',
      stringMin: 'La longueur doit être supérieure ou égale à {expected} caractères !',
      stringMax: 'La longueur doit être inférieure ou égale à {expected} caractères !',
      stringLength: 'La longueur doit être {expected} caractères !',
      stringPattern: 'Ne correspond pas au modèle requis !',
      stringContains: "Besoin d'inclure le texte '{expected} ' !",
      stringEnum: 'Ne correspond à aucune des valeurs autorisées !',
      stringNumeric: 'Ce doit être un nombre !',
      number: 'Doit être un nombre !',
      numberMin: 'Doit être supérieur ou égal à {expected} !',
      numberMax: 'Doit être inférieur ou égal à {expected} !',
      numberEqual: 'Doit être le même que {expected} !',
      numberNotEqual: 'Ne doit pas être le même que {expected} !',
      numberInteger: 'Doit être un nombre entier !',
      numberPositive: 'Le nombre doit être positif !',
      numberNegative: 'Le nombre doit être négatif !',
      array: 'Doit être une liste !',
      arrayEmpty: "Ce n'est pas forcément une liste vide !",
      arrayMin:
        'Doit être au moins {number, plural, one {1 produit} few {{number} produits} other {{number} produits}} !',
      arrayMax:
        'Ne peut pas contenir plus de {number, plural, one {1 produit} few {{number} produits} other {{number} produits}} !',
      arrayLength:
        'Doit contenir exactement {expected, plural, one {1 produit} few {{number} produits} other {{number} produits}} !',
      arrayContains: "Doit contenir le produit '{expected}' !",
      arrayEnum: "Valeur '{expected}' ne correspond à aucune des valeurs autorisées !",
      boolean: 'Doit être "oui" ou "non" !',
      function: 'Doit être une fonctionnalité !',
      date: 'Ça doit être un date !',
      dateMin: 'Doit être supérieur ou égal à {expected} !',
      dateMax: 'Doit être inférieur ou égal à {expected} !',
      forbidden: 'Interdit !',
      email: 'E-mail est correct !',
      url: 'Pas d´adresse URL valide !',
      object: 'Doit être de type Objet !',
      arrayReducedEquals: 'La valeur totale ne correspond pas à {expected} !',
      phoneNumber: "Le numéro de téléphone doit être dans un format international valide ! '+ XXX XXX XXX XXX '!",
      dateGt: 'Date doit être supérieure à {expected} !',
      dateGte: 'Date doit être supérieure ou égale à {expected} !',
      dateLt: 'Date doit être inférieure à {expected} !',
      dateLte: 'Date doit être inférieure ou égale à {expected} !',
    },
  },
};
export default messages;
